import { Box, IconButton } from "@mui/material";
import NorthIcon from '@mui/icons-material/North';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { StyledTableCell, StyledTableRow } from './StyledTableComponents';

const ServiceTableHeader = ({ columns, requestSort, sortConfig }) => {
  return (
    <StyledTableRow>
      {columns?.map(column => (
        <StyledTableCell key={column.id} style={{ minWidth: column.minWidth }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: column.id === 'doorHardwareType' ? 'pointer' : 'default',
            }}
            onClick={() => column.id === 'doorHardwareType' && requestSort(column.id)}
          >
            <b>{column.label}</b>
            {column.id === 'doorHardwareType' && (
              <Box component="span" sx={{ display: "inline-flex", alignItems: "center", marginLeft: "5px" }}>
                {sortConfig.key === column.id ? (
                  sortConfig.direction === 'ascending' ? (
                    <NorthIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </Box>
            )}
          </Box>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default ServiceTableHeader;
