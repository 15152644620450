import { Box, Typography, IconButton, Button } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react'
import BeardcrumNavigator from '../../components/BeardcrumNavigator'
import Sidebar from '../../components/Sidebar'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { serviceProductList, serviceResponseClr, updatePriceColumn, uploadProductServiceCSV } from '../../features/services/serviceSlice';
import Loading from '../../components/Loading';
import CloseIcon from '@mui/icons-material/Close';
import Alert  from "@mui/material/Alert";
import Papa from 'papaparse';
import { toTitleCase} from './Util';
import ServiceTable from './ServiceTable';
import { unwrapResult } from '@reduxjs/toolkit';
import DynamicFilter from "./DynamicFilters";
import { set } from 'date-fns';

const ServiceProducts = ({ columns }) => {
  

  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const [filters, setFilters] = React.useState({
    job_type: [],
    color: [],
    grid: [],
    dimension_class: [], 
    fit_type: [],
    is_stack: [],
    opening_type: [],
    tempered_glass: [],
    privacy: [],
    modelName:[]
  });
  

const param = useParams();
const dispatch = useDispatch();
const {isDrawerOpen} = useSelector((store) => store.login)
const {serviceDetail, isLoading, responseStatus, responseMsg, alert} = useSelector((store) => store.service)
const serviceName = localStorage.getItem('serviceName');
const importCsv = useRef();
const exportCsvLinkRef = useRef();
const [searchValue, setSearchValue] = useState('');
const [alertDialog, setAlertDialog] = React.useState(false);
const [isDataUploaded, setIsDataUploaded]  =  useState(false)
const [selectedFilter, setSelectedFilter] = React.useState("ALL");

console.log("Params: ", param?.serviceid)
console.log("sserviceDetail: ", serviceDetail)

const [interiorDoorServices, setInteriorDoorServices] = useState([
  { id: '/services/door-hardware', name: 'DOOR HARDWARE' },
  { id: '/services/door-casing', name: 'DOOR CASING' }
]);
const [editingRow, setEditingRow] = useState(null);
const [editedPrices, setEditedPrices] = useState({}); 
const [exportData, setExportData] = useState([]);

const location = useLocation();
console.log('serviceDetailserviceDetail": ',serviceDetail)

const handlePriceEdit = (rowId, currentPrice) => {

  const priceWithoutDollar = typeof currentPrice === 'string' ? currentPrice.replace(/^\$/, '') : currentPrice.toString();
  setEditingRow(rowId);
  setEditedPrices({ ...editedPrices, [rowId]: priceWithoutDollar });
  
};
const handlePriceChange = (rowId, newValue) => {
  setEditedPrices({ ...editedPrices, [rowId]: newValue });
};
const mapHeaders = (data, headerMapping, columnsConfig) => {
  const columnIds = columnsConfig.map(col => col.id); 

  return data.map(item => {
    const mappedItem = {};
    columnIds.forEach(key => {
      if (item[key] !== undefined) { 
        mappedItem[headerMapping[key] || key] = item[key];
      }
    });
    return mappedItem;
  });
};
const createHeaderMapping = (data, columnsConfig) => {
  if (data.length === 0) return {};

  const keys = Object.keys(data[0]);
  const mapping = {};

  keys.forEach(key => {
    const column = columnsConfig.find(col => col.id === key);
    if (column) {
      mapping[key] = column.label;
    } else {
      mapping[key] = key;
    }
  });

  return mapping;
};
console.log('Filtering with job_type:', filters.job_type);


const applyFilters = (selectedFilters) => {
  console.log('Current Filters Before Update:', filters);
  console.log('Applying filters: in apply filter fucntion', selectedFilters);
  
  const updatedFilters = {
    ...filters,
    ...selectedFilters,
  };

  console.log(updatedFilters , "updated filtersssssssssssssssss")
  console.log(filters , "before setting in filters state")
  
  setFilters(updatedFilters);
  console.log(filters , "after setting in filters state")

};

// Log updated filters whenever 'filters' state changes
useEffect(() => {
  console.log('Current Filters After Update:', filters);
}, [filters]); // This will run every time 'filters' state changes


const filteredData = useMemo(() => {
  if (!serviceDetail?.data) return [];

  const searchLower = searchValue.toLowerCase();
  return serviceDetail.data.filter(row => 
    columns.some(column => 
      row[column.id]?.toString().toLowerCase().includes(searchLower)
    )
  ).filter(row => {
    // Apply filters
    return Object.keys(filters).every(key => {
      if (filters[key].size > 0) {
        return filters[key].has(row[key]);
      }
      return true; // No filter for this key
    });
  });
}, [serviceDetail, columns, searchValue, filters]);


console.log('Filtered Data:', filteredData);
console.log('Selected Filter:', selectedFilter);
console.log('Current Filters:', filters);

const prepareExportData = () => {
  const headerMapping = createHeaderMapping(filteredData, columns); 
  const modifiedData = mapHeaders(filteredData, headerMapping, columns);
  setExportData(modifiedData);
  
};
const handlePriceSave = (rowId) => {
  const currentPath = location.pathname; 

  dispatch(updatePriceColumn({ id: rowId, ppui: Number(editedPrices[rowId]).toFixed(2), currentPath: currentPath }))
  .then(unwrapResult)
  .then((updatedResult) => {

    console.log('Update successful', updatedResult);
    setEditingRow(null); 
    dispatch(serviceProductList(param.serviceid)); 
  })
  .catch((error) => {
  
    console.error('Update failed', error);
    
  });
};
const breadcrumbs = [
    <Typography key="3" color="text.primary" style={{
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '34px',
        lineHeight: '36px',
        color: '#000000',
        textTransform: 'capitalize'
    }}>
      
         {toTitleCase(serviceName)}
    </Typography>
];

const getFilterValue = ()=> {
  if (param?.serviceid === "6373e9f281b3c043a0225ffe")
  {
    return {
      job_type: Array.isArray(serviceDetail?.data?.map(item => item.job_type
  
      )) 
        ? [...new Set(serviceDetail?.data?.map(item => item.job_type))] 
        : [],
      color: Array.isArray(serviceDetail?.data?.map(item => item.color)) 
        ? [...new Set(serviceDetail?.data?.map(item => item.color))] 
        : [],
      grid: Array.isArray(serviceDetail?.data?.map(item => item.grid)) 
        ? [...new Set(serviceDetail?.data?.map(item => item.grid))] 
        : [],
      // dimension_class: Array.isArray(serviceDetail?.data?.map(item => item.dimension_class)) 
      //   ? [...new Set(serviceDetail?.data?.map(item => item.dimension_class))] 
      //   : [],
      // fit_type: Array.isArray(serviceDetail?.data?.map(item => item.fit_type)) 
      //   ? [...new Set(serviceDetail?.data?.map(item => item.fit_type))] 
      //   : [],
      // is_stack: Array.isArray(serviceDetail?.data?.map(item => item.is_stack)) 
      //   ? [...new Set(serviceDetail?.data?.map(item => item.is_stack))] 
      //   : [],
      // opening_type: Array.isArray(serviceDetail?.data?.map(item => item.opening_type)) 
      //   ? [...new Set(serviceDetail?.data?.map(item => item.opening_type))] 
      //   : [],
      // tempered_glass: Array.isArray(serviceDetail?.data?.map(item => item.tempered_glass)) 
      //   ? [...new Set(serviceDetail?.data?.map(item => item.tempered_glass))] 
      //   : [],
      privacy: Array.isArray(serviceDetail?.data?.map(item => item.privacy)) 
        ? [...new Set(serviceDetail?.data?.map(item => item.privacy))] 
        : [],
    }
  
} 

else if (param?.serviceid === "64332da1beedb46ff5a0fcaf") {
  const data = serviceDetail?.data;

 console.log("Processing the following data:", data);

const uniqueValues = {
  modelName: [
    ...new Set(data?.map(item => item.modelName).filter(Boolean))
  ],  

  //2 

  wallConditionJambWidth: [
    ...new Set(data?.map(item => item.wallConditionJambWidth).filter(Boolean))
  ],

  //3
  jambWidthInches: [
    ...new Set(data?.map(item => item.jambWidthInches))
  ],

  //4

  doorWidth: [
    ...new Set(data?.map(item => item.doorWidth).filter(Boolean))
  ],

  //5
  doorWidthInches: [
    ...new Set(data?.map(item => item.doorWidthInches).filter(Boolean))
  ],
  //6
  doorHeight: [
    ...new Set(data?.map(item => item.doorHeight).filter(Boolean))
  ],

  //7
  doorHeightInches: [
    ...new Set(data?.map(item => item.doorHeightInches).filter(Boolean))
  ],
  

  // surface: [
  //   ...new Set(data?.map(item => item.surface).filter(Boolean))
  // ], 

  //8 
  unit: [
    ...new Set(data?.map(item => item.unit).filter(Boolean))
  ],

  overallFrameWidth: [
    ...new Set(data?.map(item => item.overallFrameWidth).filter(Boolean))
  ],

  overallFrameHeight: [
    ...new Set(data?.map(item => item.overallFrameHeight).filter(Boolean))
  ],


  surface: [
    ...new Set(data?.map(item => item.surface).filter(Boolean))
  ],


  thickness: [
    ...new Set(data?.map(item => item.thickness).filter(Boolean))
  ],


  doorThickness: [
    ...new Set(data?.map(item => item.overallFrameWidth).filter(Boolean))
  ],


  coreType: [
    ...new Set(data?.map(item => item.coreType).filter(Boolean))
  ],

  doorThicknessInches: [
    ...new Set(data?.map(item => item.doorThicknessInches).filter(Boolean))
  ],

  hinges: [
    ...new Set(data?.map(item => item.hinges).filter(Boolean))
  ],

  isFireRated: [
    ...new Set(data?.map(item => item.isFireRated).filter(Boolean))
  ],

  rsPrice: [
    ...new Set(data?.map(item => item.rsPrice).filter(Boolean))
  ],

  actions: [
    ...new Set(data?.map(item => item.actions).filter(Boolean))
  ],
  

};

console.log("Unique Values per Field:", {
  doorWidth: uniqueValues.doorWidth,
  doorWidthInches: uniqueValues.doorWidthInches,
  doorHeight: uniqueValues.doorHeight,
  doorHeightInches: uniqueValues.doorHeightInches,
  unit: uniqueValues.unit
});



  //console.log("Door Height Unique Values Count:", uniqueValues.doorheight.length);
  return uniqueValues;


  }  else if (param?.serviceid === "door-casing") {
    const data = serviceDetail?.data;
  
   console.log("Processing the following data:", data);
  
  const uniqueValues = {
    casingStyle: [
      ...new Set(data?.map(item => item.casingStyle).filter(Boolean))
    ],  
    doorTypeSupported: [
      ...new Set(data?.map(item => item.doorTypeSupported).filter(Boolean))
    ],  
  
    heightsAvailable: [
      ...new Set(data?.map(item => item.heightsAvailable).filter(Boolean))
    ],  
  
    
    casingSize: [
      ...new Set(data?.map(item => item.casingSize).filter(Boolean))
    ],  
  
    
    reliPrice: [
      ...new Set(data?.map(item => item.reliPrice).filter(Boolean))
    ],  

    
  };
    return uniqueValues;
  
  
    }
    else if (param?.serviceid === "door-hardware") {
      const data = serviceDetail?.data;
    
     console.log("Processing the following data:", data);
    
    const uniqueValues = {
      doorHardwareType: [
        ...new Set(data?.map(item => item.doorHardwareType).filter(Boolean))
      ],  
      color: [
        ...new Set(data?.map(item => item.color).filter(Boolean))
      ],  
    
      style: [
        ...new Set(data?.map(item => item.style).filter(Boolean))
      ],  
    
      
      brand: [
        ...new Set(data?.map(item => item.brand).filter(Boolean))
      ],  
    
      catalog: [
        ...new Set(data?.map(item => item.catalog).filter(Boolean))
      ],  
      
      partNumber: [
        ...new Set(data?.map(item => item.partNumber).filter(Boolean))
      ],  
  
      
    };
      return uniqueValues;
    
    
      }
  else{

    const data = serviceDetail?.data;
    return {
      sizeType: [
        ...new Set(data?.map(item => item.sizeType).filter(Boolean))
      ],

      jobType: [
        ...new Set(data?.map(item => item.jobType).filter(Boolean))
      ],

      color: [
        ...new Set(data?.map(item => item.color).filter(Boolean))
      ],

      widthGreaterThan: [
        ...new Set(data?.map(item => item.widthGreaterThan).filter(Boolean))
      ],

      widthLessThanEqualTo: [
        ...new Set(data?.map(item => item.widthLessThanEqualTo).filter(Boolean))
      ],

      heightGreaterThan: [
        ...new Set(data?.map(item => item.heightGreaterThan).filter(Boolean))
      ],

      heightLessThanEqualTo: [
        ...new Set(data?.map(item => item.heightLessThanEqualTo).filter(Boolean))
      ],

      panels: [
        ...new Set(data?.map(item => item.panels).filter(Boolean))
      ],

      type: [
        ...new Set(data?.map(item => item.type).filter(Boolean))
      ],

      glassType: [
        ...new Set(data?.map(item => item.glassType))
      ],

      ppui: [
        ...new Set(data?.map(item => item.ppui).filter(Boolean))
      ],
    };
    //console.log("Door Height Unique Values Count:", uniqueValues.doorheight.length);
  }
}

const serviceid = "64332da1beedb46ff5a0fcaf";
const isServiceIdMatch = serviceDetail?.data?.some(service => service.id === serviceid);

// Log the result
console.log(isServiceIdMatch, "internal doors coloumsssssssssssss"); 

const getFilterHeader = (serviceid) => {
  let filterHeader = [];
  if (serviceid === "6373e9f281b3c043a0225ffe") {
    filterHeader = ["job_type", "color", "grid", "privacy"];
  } else if (serviceid === "64332da1beedb46ff5a0fcaf") {
    filterHeader = ["modelName", "wallConditionJambWidth", "jambWidthInches" ,
       "doorWidth", "doorWidthInches" , "doorHeight" , "doorHeightInches" , 
       "unit" , "overallFrameWidth" , "overallFrameHeight" , "surface" , "thickness" , 
       "doorThickness" , "coreType" , "doorThicknessInches" , "hinges" ,  "rsPrice"];
  }
  
  else if (serviceid === "door-casing") {
    filterHeader = ["casingStyle" , "doorTypeSupported" ,"heightsAvailable" , "casingSize" , "reliPrice"];
  }  else if (serviceid === "door-hardware") {
    filterHeader = ["doorHardwareType" , "color" ,"style" , "brand" , "catalog" , "partNumber"];
  }else {
  filterHeader = ["sizeType", "jobType", "color" , "widthGreaterThan" ," widthLessThanEqualTo" ,"heightGreaterThan" , 
    " heightLessThanEqualTo" , "panels" , "type" ,"glassType" , "ppui" ];
  }
  return filterHeader;
};

const resetFilters = () => {
  setFilters(filters);
};

const handleSearch = (searchedValue) => {
  setSearchValue(searchedValue?.toLowerCase())

}
const isMatchUrl = (url) => {
  return location.pathname.includes(url);
}
const handleImportCsv = () => {
  importCsv.current.click();
}

const handleUploadCsv = (e) => {
  const serviceId = param.serviceid;
  const file = e.target.files[0]; 
  if (file) {
    setIsDataUploaded(true); // Indicate upload is starting
    Papa.parse(file, {
      header: true,
      complete: function(results) {
        console.log("Parsed CSV Data:", results.data);
        dispatch(uploadProductServiceCSV({
          csvData: results.data, 
          service: serviceId,
          fileName: file.name,
        }))
        .then(unwrapResult) 
        .then(() => {
      
          dispatch(serviceProductList(serviceId));
        })
        .catch((error) => {
          console.error('Upload failed or data refresh failed', error);
        })
        .finally(() => {
          setIsDataUploaded(false); 
        });
      },
    });
  }
};


useEffect(() => {
  dispatch(serviceProductList(param.serviceid));
}, [dispatch, param.serviceid]);


if (isLoading || !serviceDetail || isDataUploaded) {
  return <Loading />;
}

console.log(serviceDetail , "service datsilsssssssss")

  return (
    <div className="page-section">
      <Sidebar/>
      <Box className="page-content" sx={{width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: 'hidden'}}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <BeardcrumNavigator breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}/>
          <Box component="div" sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}>
            
            {isMatchUrl('64332da1beedb46ff5a0fcaf') ? (
          <>
            {interiorDoorServices.map((service) => (
              <Link to={service.id} key={service.id}     onClick={() => {
                let serviceName = service.name.toLowerCase();
                console.log(service.name , " service----------nameeeee"); 
                if (serviceName.includes('hardware')) {
                  localStorage.setItem('serviceName', 'interior door hardware');
                } else if (serviceName.includes('casing')) {
                  localStorage.setItem('serviceName', 'interior door casing');
                } else {
                  localStorage.setItem('serviceName', service.name);
                }
              }}>
                <Button variant="contained" className="bc-btn-outline">
                  {service.name} 
                </Button>
                </Link>
              ))}
              </>
            ) : <></>}


         
          <CSVLink data={filteredData ? exportData : []} filename={`export-${serviceName}.csv`}  onClick={prepareExportData}   ref={exportCsvLinkRef}>
            <Button variant="outlined" className="bc-btn-outline" color="primary">Export csv</Button>
          </CSVLink>
            <Button variant="contained" className="bc-btn-outline" color="primary" onClick={handleImportCsv}>Import csv</Button>
            <input type="file" name="importCsv" id="importCsv" style={{display: 'none'}} accept='text/csv' ref={importCsv}  onChange={handleUploadCsv} />

            
          </Box>
     
          
        </Box>

        <Box
              component='div'
              sx={{
              //  display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                float: 'right',
              }}
            >
              <Button
                variant='outlined'
                className='bc-btn-outline'
                color='primary'
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </Button>
            </Box> 
          
        <Box component="div" sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
          mb: 3,
        }}>
          <Box component="div">
          <Box
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "33px",
                height: 50,
                display: "flex",
                alignItems: "center",
                maxWidth: "208px",
                maxHeight: '30px',
                border: "1px solid #ddd",
                overflow: "hidden",
              }}
            >
              <SearchRoundedIcon
                sx={{
                  width: "16%",
                  marginLeft: "6px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                // placeholder="Search"
                className="search-input"
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              />
            </Box>

        
          </Box>
          
        </Box>
        {
              alert ? (
                <Alert 
                  severity={responseStatus}
                  color={responseStatus} 
                  sx={{mb: 3, width: '100%'}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        dispatch(serviceResponseClr(false));
                        setAlertDialog(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >{responseMsg}</Alert>
              ) : null
            }
    
          {serviceDetail?.data ? (
            <ServiceTable
                data={filteredData}
                columns={columns}
                searchValue={searchValue}
                editingRow={editingRow}
                editedPrices={editedPrices}
                onEditPrice={handlePriceEdit}
                onPriceChange={handlePriceChange}
                onSavePrice={handlePriceSave}
                priceFieldName={  location.pathname.includes('64332da1beedb46ff5a0fcaf') ? 'rsPrice' : 'ppui'}

            />
            ) : <></>}
      </Box>
      <DynamicFilter
       open={filterModalOpen}
       onClose={() => {
        setFilterModalOpen(false);
        resetFilters();
      }}
       filters={getFilterValue(param.serviceid)}
       filterHeader={getFilterHeader(param.serviceid)}
       applyFilters={(data) => {
      // handleFilterClicked(data); 
  }}
        onApply={applyFilters}
/>

{/* filterHeader={[
    "Jobtype", "Color", "Grid", "Dimension Class", "Fit Type", "Is Stack",
    "Opening Type", "Tempered Glass", "Privacy"
  ]} */}

    </div>
  )
}

export default ServiceProducts


