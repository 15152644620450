import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Box, IconButton, Button } from '@mui/material';
import { handleEditUserModal } from '../../features/login/loginSlice';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRef, useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { editStaff, getUsers, staffDetail, resetStaffDetail } from '../../features/userInfo/userInfoSlice';
import { useParams } from 'react-router-dom';

const EditUserModal = ({ userId }) => {
  const { isEditUserModal } = useSelector((store) => store.login);
  const { companyDetail } = useSelector((store) => store.company);
  const { staff } = useSelector((store) => store.userInfo);
  const dispatch = useDispatch();
  const param = useParams();

  const [status, setStatus] = useState(false);
  const [accountType, setAccountType] = useState('Standard');
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);

  const imgInput = useRef(null);
  const imgRef = useRef(null);
  const formikRef = useRef(); // Ref for accessing Formik instance

  useEffect(() => {
    dispatch(staffDetail(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (staff?.data) {
      setStatus(staff.data.statusBit);
      setAccountType(staff.data.accountType === 'true' ? 'Admin' : 'Standard');
      setInitialValues({
        name: staff.data.firstName || '',
        email: staff.data.email || '',
        phone: staff.data.phoneNumber || '',
        approvedByReli: staff.data.approvedByReli || 'accepted',
        status: staff.data.statusBit,
        accountType: staff.data.accountType === 'true' ? 'Admin' : 'Standard',
        image: staff.data.profileImage || '/images/circle-gray.png'
      });
    }
  }, [staff]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    phone: '',
    approvedByReli: 'accepted',
    status: false,
    accountType: 'Standard',
    image: '/images/circle-gray.png'
  });

  const pickFile = () => {
    imgInput.current.click();
  };

  const handleAddImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      imgRef.current.src = url;
      URL.revokeObjectURL(file);
      setIsImageChanged(true);
      setIsImageRemoved(false);
    }
  };

  const clearImgRef = () => {
    imgInput.current.value = '';
    imgRef.current.src = '/images/circle-gray.png';
    setIsImageChanged(false);
    setIsImageRemoved(true);
  };

  const isCustomDirty = () => {
    if (!formikRef.current) {
      return false;
    }
    const formValues = formikRef.current.values;
    const initialFormValues = initialValues;

    const formValuesForComparison = {
      ...formValues,
      accountType: formValues.accountType === 'Admin',
    };
    const initialFormValuesForComparison = {
      ...initialFormValues,
      accountType: initialFormValues.accountType === 'Admin',
    };

    return JSON.stringify(formValuesForComparison) !== JSON.stringify(initialFormValuesForComparison);
  };

  return (
    <>
      <Dialog
        open={isEditUserModal && staff?.data}
        scroll='body'
        onClose={() => {
          dispatch(resetStaffDetail());
          dispatch(handleEditUserModal());
        }}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{
          p: 1,
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '24px',
          letterSpacing: '0.18px',
          color: '#000000'
        }}>Company User</DialogTitle>
        <Box component="div" sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Box component="div" className='addImg'>
            <img
              src={staff?.data?.profileImage ? staff.data.profileImage : '/images/circle-gray.png'}
              alt=""
              ref={imgRef}
              style={{ width: '', height: '100%', objectFit: 'cover' }}
            />
            <input type="file" hidden id="getImg" ref={imgInput} onChange={handleAddImg} />
          </Box>
          <Box component="div">
            <IconButton onClick={pickFile}>
              <ModeEditOutlineRoundedIcon />
            </IconButton>
            <IconButton onClick={clearImgRef}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Typography sx={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '20px',
            letterSpacing: '0.4px',
            color: '#000000'
          }}>
            Company Name: {companyDetail.data?.findCompany.companyName}
          </Typography>
        </Box>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            const submissionValues = {
              ...values,
              company: param.companyid,
              status,
              accountType: values.accountType === 'Admin',
              image: isImageChanged ? imgInput.current.files[0] : (isImageRemoved ? null : staff.data.profileImage),
              id: userId
            };
            dispatch(editStaff(submissionValues));
            dispatch(handleEditUserModal());
            dispatch(getUsers(param.companyid));
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
              .required('Please enter the contractor name'),
            email: Yup.string()
              .email('Please enter a valid email address')
              .required('Please enter an email address'),
            phone: Yup.string()
              .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit phone number")
              .required('Please enter a phone number'),
          })}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <DialogContent sx={{ p: 0 }}>
                <DialogContentText
                  component={'div'}
                  id="scroll-dialog-description"
                  tabIndex={-1}
                >
                  <Container maxWidth='xs'>
                    <Field as={TextField}
                      sx={{ width: '100%', mb: 4, mt: 5 }}
                      id="firstName"
                      name="name"
                      label="Name"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      error={Boolean(errors.name) && Boolean(touched.name)}
                      helperText={Boolean(touched.name) && errors.name}
                    />
                    <Field as={TextField}
                      sx={{ width: '100%', mb: 4 }}
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      error={Boolean(errors.email) && Boolean(touched.email)}
                      helperText={Boolean(touched.email) && errors.email}
                      disabled={true}
                    />
                    <Field as={TextField}
                      sx={{ width: '100%', mb: 4 }}
                      id="phone"
                      name="phone"
                      label="Phone"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      error={Boolean(errors.phone) && Boolean(touched.phone)}
                      helperText={Boolean(touched.phone) && errors.phone}
                    />
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <InputLabel id="approvedByReli">Approved by Reli</InputLabel>
                      <Field as={Select}
                        labelId="approvedByReli"
                        id="approvedByReli"
                        name="approvedByReli"
                        label="Approved by Reli"
                        error={Boolean(errors.approvedByReli) && Boolean(touched.approvedByReli)}
                        helperText={Boolean(touched.approvedByReli) && errors.approvedByReli}
                      >
                        <MenuItem value='accepted'>Accepted</MenuItem>
                        <MenuItem value='pending'>Pending</MenuItem>
                        <MenuItem value='rejected'>Rejected</MenuItem>
                      </Field>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <InputLabel id="accountType">Account Type</InputLabel>
                      <Field as={Select}
                        labelId="accountType"
                        id="accountType"
                        name="accountType"
                        label="Account Type"
                        error={Boolean(errors.accountType) && Boolean(touched.accountType)}
                        helperText={Boolean(touched.accountType) && errors.accountType}
                        value={accountType}
                        onChange={(e) => {
                          setAccountType(e.target.value);
                          setFieldValue('accountType', e.target.value);
                        }}
                      >
                        <MenuItem value='Standard'>Standard</MenuItem>
                        <MenuItem value='Admin'>Admin</MenuItem>
                      </Field>
                    </FormControl>
                  </Container>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default EditUserModal;
