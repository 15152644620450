import {
  Box,
  Table,
  TableContainer,
  Typography,

  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import BeardcrumNavigator from "../../components/BeardcrumNavigator";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import TableLink from "../../components/TableLink";
import { getTransactions } from "../../features/transactions/transactionSlice";
import Loading from "../../components/Loading";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { CSVLink } from "react-csv";
import moment from "moment/moment";

const columns = [
  {
    id: "uniqueTransactionID",
    label: "Transaction ID",
    minWidth: 150,
    fontWeight: "600",
  },
  { id: "uniqueProjectID", label: "Project ID", minWidth: 150, fontWeight: "600" },
  { id: "name", label: "Name", minWidth: 150, fontWeight: "600" },
  { id: "payeeType", label: "Payee Type", minWidth: 150, fontWeight: "600" },
  { id: "serviceType", label: "Service Type", minWidth: 150, fontWeight: "600" },
  {
    id: "transactionType",
    label: "Transaction Type",
    minWidth: 150,
    fontWeight: "600",
  },
  { id: "city", label: "City", minWidth: 100, fontWeight: "600" },
  { id: "state", label: "State", minWidth: 100, fontWeight: "600" },
  { id: "zip", label: "Zip", minWidth: 100, fontWeight: "600" },
  { id: "orderDate", label: "Ordered", minWidth: 150, fontWeight: "600" },
  { id: "scheduledDate", label: "Scheduled", minWidth: 150, fontWeight: "600" },
  { id: "completedDate", label: "Completed", minWidth: 150, fontWeight: "600" },
  { id: "totalAmount", label: "Amount", minWidth: 100, fontWeight: "600" },
  { id: "discountCode", label: "Coupon Code", minWidth: 150, fontWeight: "600" },
  { id: "discountAmount", label: "Discount Value", minWidth: 150, fontWeight: "600" },  
];



function identifyProduct(str) {
  if (/liding/i.test(str)) {
    return 'Sliding Glass Door';
  } else if (/indow/i.test(str)) {
    return 'Window';
  }
   else if (/terior/i.test(str)) {
    return 'Interior Door';
  } else {
    return 'Unknown product';
  }
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#ddd",
  },
}));
const Transactions = () => {
  const { isDrawerOpen } = useSelector((store) => store.login);
  const { isLoading, transactions } = useSelector((store) => store.transaction);
  console.log('transactions:ASDASD: ',transactions)
  const dispatch = useDispatch();

  const breadcrumbs = [
    <Typography
      key="3"
      color="text.primary"
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "34px",
        lineHeight: "36px",
        color: "#000000",
      }}
    >
      Transactions
    </Typography>,
  ];
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getCsvData = () => {
    if (!transactions.data) {
      return []; 
    }
  
    return transactions.data.map(row => {
      const orderDetails = row.orderdetails || []; 
      return {
        "Transaction Id": row.uniqueTransactionID || '',
        "Project ID": row.uniqueProjectID || "",   
        "Name": row.name || "",
        "Payee Type": "Customer",
        "Service Type": orderDetails.length > 1
          ? identifyProduct(orderDetails[0]?.serviceName)
          : orderDetails.map(service => identifyProduct(service.serviceName)).join(', '),
        "Transaction Type": row.transactionType || "",
        City: orderDetails.length > 1
          ? orderDetails[0]?.property?.city || ""
          : orderDetails.map(service => service?.property?.city || '').join(', '),
        State: orderDetails.length > 1
          ? orderDetails[0]?.property?.state || ""
          : orderDetails.map(service => service?.property?.state || '').join(', '),
        Zip: orderDetails.length > 1
          ? orderDetails[0]?.property?.zipCode || ""
          : orderDetails.map(service => service?.property?.zipCode || '').join(', '),
        Ordered: row.dateOrdered ? moment(row.dateOrdered).format('MM/DD/YYYY') : "",
        Scheduled: row.dateScheduled ? moment(row.scheduledDate).format('MM/DD/YYYY') : "",
        Completed: row.dateCompleted ? moment(row.completedDate).format('MM/DD/YYYY') : "",
        Amount: row.totalAmount ? '$' + row.totalAmount.toFixed(2) : '$0.00',
        "Coupon Code": row.discountCode || "",
        "Discount Value": row.discountAmount ? '$' + row.discountAmount : 'N/A',
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  const handleSearch = (searchedValue) => {
    console.log('searchedValue : ',searchedValue)
    setSearchValue(searchedValue)
    setPage(0)
  }

  if(isLoading){
    return (
      <Loading/>
    )
  }

  return (
    <div className="page-section">
      <Sidebar />
      <Box
        className="page-content"
        sx={{
          width: isDrawerOpen ? `calc(100% - 240px)` : `calc(100% - 57px)`, overflow: "hidden"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <BeardcrumNavigator
            breadcrumbs={breadcrumbs ? breadcrumbs : "Beardcrums"}
          />
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CSVLink            
               data={getCsvData() || ""}
            >
              <Button
                variant="outlined"
                className="bc-btn-outline"
                color="primary"
                >
                Export csv
              </Button>
            </CSVLink>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box component="div">
            {/* <SearchBox /> */}
            <Box sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '33px',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            maxWidth: "208px",
            maxHeight: '30px',
            border: '1px solid #ddd',
            overflow: 'hidden'
        }}>
            <SearchRoundedIcon sx={{
              width: '16%',
              marginLeft: '6px'
            }}/>
            <input type="text" value={searchValue}   style={{ textTransform: 'none' }} className='search-input' onChange={(e) =>  handleSearch(e.target.value)} />
        </Box>
          </Box>
          {/* <IconButton aria-label="filter-icon" size="large">
            <FilterListRoundedIcon />
          </IconButton> */}
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" sx={{}}>
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: column.fontWeight }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
  {transactions.data
    ?.filter((data) => {
      const lowerCasedSearchValue = searchValue.toLowerCase();

      // Check if any of these fields match the search value
      return (
        data.uniqueTransactionID?.toLowerCase().includes(lowerCasedSearchValue) || // Transaction ID
        data.uniqueProjectID?.toLowerCase().includes(lowerCasedSearchValue) || // Project ID
        data.name?.toLowerCase().includes(lowerCasedSearchValue) // Name
      );
    })
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => {
      return (
        <StyledTableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={parseInt(Date.now() * Math.random())}
        >
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <StyledTableCell key={column.id} align={column.align}>
                {/* Your logic for rendering different columns goes here */}
                {column.id === 'uniqueProjectID' ? (
                  <TableLink text={value} route={`/projects/` + row._id} />
                ) : column.id === 'name' ? (
                  <TableLink text={value} route={`/customers/` + row.user} />
                ) : column.id === 'payeeType' ? (
                  'Customer'
                ) : column.id === 'serviceType' ? (
                  row.orderdetails?.length > 1
                    ? identifyProduct(row.orderdetails[0].serviceName)
                    : row.orderdetails
                        ?.map((service, index) =>
                          identifyProduct(service.serviceName) + (row.orderdetails.length - 1 === index ? '' : ', ')
                        )
                ) : column.id === 'transactionType' ? (
                  value
                ) : column.id === 'city' ? (
                  row.orderdetails?.length > 1
                    ? row.orderdetails[0]?.property?.city
                    : row.orderdetails
                        ?.map((service, index) =>
                          service?.property?.city + (row.orderdetails.length - 1 === index ? '' : ', ')
                        )
                ) : column.id === 'state' ? (
                  row.orderdetails?.length > 1
                    ? row.orderdetails[0]?.property?.state
                    : row.orderdetails
                        ?.map((service, index) =>
                          service?.property?.state + (row.orderdetails.length - 1 === index ? '' : ', ')
                        )
                ) : column.id === 'zip' ? (
                  row.orderdetails?.length > 1
                    ? row.orderdetails[0]?.property?.zipCode
                    : row.orderdetails
                        ?.map((service, index) =>
                          service?.property?.zipCode + (row.orderdetails.length - 1 === index ? '' : ', ')
                        )
                ) : column.id === 'orderDate' ? (
                  moment(row.dateOrdered).format('MM/DD/YYYY')
                ) : column.id === 'scheduledDate' ? (
                  row.dateScheduled ? moment(row.scheduledDate).format('MM/DD/YYYY') : ''
                ) : column.id === 'completedDate' ? (
                  row.dateCompleted ? moment(row.completedDate).format('MM/DD/YYYY') : ''
                ) : column.id === 'discountAmount' ? (
                  row.discountAmount ? '$' + row.discountAmount : 'N/A'
                ) : column.id === 'discountCode' ? (
                  row.discountCode
                ) : column.id === 'totalAmount' ? (
                  row.totalAmount ? '$' + row.totalAmount.toFixed(2) : '$0.00'
                ) : column.format && typeof value === 'number' ? (
                  column.format(value)
                ) : (
                  value
                )}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      );
    })}
</TableBody>;

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transactions.data?.length ? transactions.data?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default Transactions;
